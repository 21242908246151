import { Component, OnInit } from '@angular/core'
import { ThemeService } from 'src/app/services/theme.service'

@Component({
    selector: 'app-documentation',
    templateUrl: './documentation.component.html',
    styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

    constructor(private themeService: ThemeService) { }

    ngOnInit() {
    }

    setTheme(themeName: string) {
        this.themeService.setTheme(themeName)
    }
}
