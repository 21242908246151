import { Component, OnInit } from '@angular/core'
import { ThemeService } from 'src/app/services/theme.service'

@Component({
    selector: 'app-general-items',
    templateUrl: './general-items.component.html',
    styleUrls: ['./general-items.component.css']
})
export class GeneralItemsComponent implements OnInit {

    constructor(private themeService: ThemeService) { }

    ngOnInit() {
    }

    setTheme(themeName: string) {
        this.themeService.setTheme(themeName)
    }
}
