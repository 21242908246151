<div class="box">
    <h1 class="title primary">Code here</h1>
    <div style="display: flex;">
        <div style="width: 50%;">
            <pre class="language-markup line-numbers">
                <code>
                    &lt;div>Hello Pakistan
                        &lt;a href="https:\\google.com">&lt;/a>
                        &lt;a href="https:\\google.com">&lt;/a>
                    &lt;/div>
                </code>
            </pre>
        </div>

        <div style="width: 48%; margin-left: 1%">
            <pre class="language-css line-numbers">
                <code>
                    .main-container {{ '{' }}
                        display: flex;
                        justify-content: center;
                    }
                </code>
            </pre>
        </div>
    </div>
</div>

<div class="d-flex">
    <div class="box w-50">
        <div class="table-responsive">
            <ng-container [ngTemplateOutlet]="table" [ngTemplateOutletContext]="{configs: {sticky: true, classes: 'alternate-rows', highlight1: true}}"></ng-container>
        </div>
    </div>

    <div class="box w-50 ms-1">
        <div class="table-responsive">
            <ng-container [ngTemplateOutlet]="table" [ngTemplateOutletContext]="{configs: {sticky: false, classes: 'alternate-cols'}}"></ng-container>
        </div>
    </div>
</div>

<ng-template #table let-configs="configs">
    <table class="cg primary" [class]="configs?.classes">
        <thead [class.sticky]="configs?.sticky">
            <tr>
                <th [class.highlight]="configs?.highlight1">Class name</th>
                <th [class.highlight]="configs?.highlight2">Type</th>
                <th [class.highlight]="configs?.highlight3">Trainer</th>
                <th [class.highlight]="configs?.highlight4">Spots</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>Like a butterfly</td>
                <td>Boxing</td>

                <td>Aaron Chapman</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Mind &amp; Body</td>
                <td>Yoga</td>

                <td>Adam Stewart</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Crit Cardio</td>
                <td>Gym</td>

                <td>Aaron Chapman</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Wheel Pose Full Posture</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Playful Dancer's Flow</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Zumba Dance</td>
                <td>Dance</td>

                <td>Donna Wilson</td>
                <td>20</td>
            </tr>
            <tr>
                <td>Cardio Blast</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Pilates Reformer</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Supple Spine and Shoulders</td>
                <td>Yoga</td>

                <td>Randy Porter</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Yoga for Divas</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>20</td>
            </tr>
            <tr>
                <td>Virtual Cycle</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>20</td>
            </tr>
            <tr>
                <td>Like a butterfly</td>
                <td>Boxing</td>

                <td>Aaron Chapman</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Mind &amp; Body</td>
                <td>Yoga</td>

                <td>Adam Stewart</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Crit Cardio</td>
                <td>Gym</td>

                <td>Aaron Chapman</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Wheel Pose Full Posture</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Playful Dancer's Flow</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Zumba Dance</td>
                <td>Dance</td>

                <td>Donna Wilson</td>
                <td>20</td>
            </tr>
            <tr>
                <td>Cardio Blast</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Pilates Reformer</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>10</td>
            </tr>
            <tr>
                <td>Supple Spine and Shoulders</td>
                <td>Yoga</td>

                <td>Randy Porter</td>
                <td>15</td>
            </tr>
            <tr>
                <td>Yoga for Divas</td>
                <td>Yoga</td>

                <td>Donna Wilson</td>
                <td>20</td>
            </tr>
            <tr>
                <td>Virtual Cycle</td>
                <td>Gym</td>

                <td>Randy Porter</td>
                <td>20</td>
            </tr>
        </tbody>
    </table>
</ng-template>
