<div class="box">
    <h1 class="primary">Buttons</h1>
    <p>This is main Container. Should be applied on all pages. This is main Container. Should be applied on all pages. This is main Container. Should be applied on all pages</p>

    <div class="buttons-container">
        <button class="cg primary lg">Primary & Large</button>
        <button class="cg secondary">Secondary & Normal</button>
        <button class="cg success sm">Success & Small</button>
        <button class="cg danger xs">Danger & Extra Small</button>
    </div>

    <div class="buttons-container">
        <button class="cg primary lg in-progress">
            <div class="loading-dots">Next
                <span></span><span></span><span></span>
            </div>
        </button>

        <button class="cg secondary in-progress">
            <div class="loading-dots">Next
                <span></span><span></span><span></span>
            </div>
        </button>

        <button class="cg success sm in-progress">
            <div class="loading-dots">Next
                <span></span><span></span><span></span>
            </div>
        </button>

        <button class="cg danger xs in-progress">
            <div class="loading-dots">Next
                <span></span><span></span><span></span>
            </div>
        </button>
    </div>

    <div class="buttons-container">
        <div class="cg-button-group">
            <div class="icon primary pre">
                <i class="fa fa-angle-left"></i>
            </div>
            <button class="cg post primary lg">Search</button>
            <div class="icon primary post">
                <i class="fa fa-angle-right"></i>
            </div>
        </div>

        <div class="cg-button-group">
            <div class="icon secondary pre">
                <i class="fa fa-angle-left"></i>
            </div>
            <button class="cg secondary">Search</button>
        </div>

        <div class="cg-button-group">
            <button class="cg post warning sm">Search</button>
            <div class="icon post warning">
                <i class="fa fa-angle-right"></i>
            </div>
        </div>

        <div class="cg-button-group">
            <div class="icon danger pre">
                <i class="fa fa-angle-left"></i>
            </div>
            <button class="cg info xs">Search</button>
        </div>
    </div>

    <div class="buttons-container">
        <div class="cg-button-group">
            <button class="cg post primary lg">Users</button>
            <label>
                <div class="icon primary post">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <input type="checkbox">
                <div class="button-menu primary">
                    <ul>
                        <li>Add New User</li>
                        <li>Edit this User</li>
                        <li>Activate</li>
                        <li>Other Actions</li>
                    </ul>
                </div>
            </label>
        </div>

        <div class="cg-button-group">
            <button class="cg post secondary">Users</button>
            <label>
                <div class="icon secondary post">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <input type="checkbox">
                <div class="button-menu secondary">
                    <ul>
                        <li>Add New User</li>
                        <li>Edit this User</li>
                        <li>Activate</li>
                        <li>Other Actions</li>
                    </ul>
                </div>
            </label>
        </div>

        <div class="cg-button-group">
            <button class="cg post info sm">Users (Right Menu)</button>
            <label>
                <div class="icon info post">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <input type="checkbox">
                <div class="button-menu info align-right">
                    <ul>
                        <li>Add New User</li>
                        <li>Edit this User</li>
                        <li>Activate</li>
                        <li>Other Actions</li>
                    </ul>
                </div>
            </label>
        </div>

        <div class="cg-button-group">
            <button class="cg post warning xs">Users</button>
            <label>
                <div class="icon warning post">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <input type="checkbox">
                <div class="button-menu warning">
                    <ul>
                        <li>Add New User</li>
                        <li>Edit this User</li>
                        <li>Activate</li>
                        <li>Other Actions</li>
                    </ul>
                </div>
            </label>
        </div>
    </div>
</div>

<div class="box">
    <h1 class="title primary">Inputs</h1>

    <div class="inputs-container">
        <div class="cg-input-group">
            <label>Simple Input</label>
            <input type="text" class="cg" placeholder="First Name" />
        </div>

        <div class="cg-input-group">
            <label>Input with Pre & Post</label>
            <div class="group">
                <div class="pre">
                    <button class="cg success">Users</button>
                </div>
                <input type="text" class="cg post" placeholder="Search Users" />
                <div class="post">
                    <button class="cg primary">Search</button>
                </div>
            </div>
        </div>

        <div class="cg-input-group">
            <label>Input with Post</label>
            <div class="group">
                <input type="text" class="cg post" placeholder="Search Users" />
                <div class="post">
                    <button class="cg primary">Search</button>
                </div>
            </div>
        </div>

        <div class="cg-input-group">
            <label>Input with Pre Icon</label>
            <div class="group">
                <div class="pre">
                    <button class="cg secondary">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <input type="text" class="cg" placeholder="Search Users" />
            </div>
        </div>
    </div>

    <div class="inputs-container">
        <div class="cg-input-group">
            <label>Simple Select</label>
            <select class="cg">
                <option>Option 1</option>
                <option>Option 2</option>
                <option>Option 3</option>
            </select>
        </div>

        <div class="cg-input-group">
            <label>Select with Pre & Post</label>
            <div class="group">
                <div class="pre">
                    <button class="cg success">Users</button>
                </div>
                <select class="cg post">
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                </select>
                <div class="post">
                    <button class="cg primary">Search</button>
                </div>
            </div>
        </div>

        <div class="cg-input-group">
            <label>Select with Post</label>
            <div class="group">
                <select class="cg post">
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                </select>
                <div class="post">
                    <button class="cg primary">Search</button>
                </div>
            </div>
        </div>

        <div class="cg-input-group">
            <label>Select with Pre Icon</label>
            <div class="group">
                <div class="pre">
                    <button class="cg secondary">
                        <i class="fa fa-search"></i>
                    </button>
                </div>
                <select class="cg">
                    <option>Option 1</option>
                    <option>Option 2</option>
                    <option>Option 3</option>
                </select>
            </div>
        </div>
    </div>
</div>

<div class="box">
    <h1 class="title primary">Checkboxes</h1>
    <div class="d-flex w-100 mb-3">
        <div class="cg checkbox-container text-right">
            <label class="cg checkbox primary">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">checkbox with right text</span>
        </div>

        <div class="cg checkbox-container text-bottom">
            <label class="cg checkbox primary">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">checkbox with bottom text</span>
        </div>

        <div class="cg checkbox-container text-top">
            <span class="text">checkbox with top aligned text</span>
            <label class="cg checkbox primary">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
        </div>

        <!-- Checkbox with text -->
        <div class="ms-5"></div>
        <label class="cg checkbox primary">
            <input type="checkbox" checked="checked">
            <span class="checkmark"></span>
        </label>
    </div>

    <hr />

    <div class="d-flex w-100 justify-content-around">
        <div class="cg checkbox-container text-right">
            <label class="cg checkbox secondary">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">Secondary</span>
        </div>

        <div class="cg checkbox-container text-right">
            <label class="cg checkbox success">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">Success</span>
        </div>

        <div class="cg checkbox-container text-right">
            <label class="cg checkbox info">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">Info</span>
        </div>

        <div class="cg checkbox-container text-right">
            <label class="cg checkbox warning">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">Warning</span>
        </div>

        <div class="cg checkbox-container text-right">
            <label class="cg checkbox danger">
                <input type="checkbox" checked="checked">
                <span class="checkmark"></span>
            </label>
            <span class="text">Danger</span>
        </div>
    </div>
</div>

<div class="box">
    <h1 class="title primary">Radio Buttons</h1>

    <h2 class="title secondary">Horizontal</h2>
    <div class="radio-group horizontal">
        <div class="radio-container">
            <label class="radio primary">
                <input type="radio" name="hradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option A</span>
        </div>

        <div class="radio-container">
            <label class="radio secondary">
                <input type="radio" checked="true" name="hradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option B to be choosen</span>
        </div>

        <div class="radio-container">
            <label class="radio success">
                <input type="radio" name="hradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option C</span>
        </div>

        <div class="radio-container">
            <label class="radio warning">
                <input type="radio" name="hradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option D</span>
        </div>
    </div>

    <h2 class="title secondary mt-3">Vertical</h2>
    <div class="radio-group vertical">
        <div class="radio-container">
            <label class="radio primary">
                <input type="radio" checked="checked" name="vradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option A</span>
        </div>

        <div class="radio-container">
            <label class="radio secondary">
                <input type="radio" name="vradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option B to be choosen</span>
        </div>

        <div class="radio-container">
            <label class="radio success">
                <input type="radio" name="vradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option C</span>
        </div>

        <div class="radio-container">
            <label class="radio warning">
                <input type="radio" name="vradio">
                <span class="checkmark"></span>
            </label>
            <span class="text">I am Option D</span>
        </div>
    </div>
</div>

<div class="box">
    <h1 class="title primary">Typography</h1>
    <hr />
    <h1 class="title primary">H1 Title with Primary Color</h1>
    <h2 class="title secondary">H2 Title with Secondary Color</h2>
    <h3 class="title success">H3 Title with Success Color</h3>
    <p class="primary">This is paragraph with primary color</p>
</div>


<div class="box">
    <h1 class="title primary">Badges</h1>
    <h2 class="title mb-2 mt-1">Standard Size Badges</h2>
    <div class="d-flex justify-content-between">
        <div class="cg-badge success">Active</div>
        <div class="cg-badge warning">InActive</div>
        <div class="cg-badge danger">Negative Growth</div>
        <div class="cg-badge primary">Positive Growth</div>
        <div class="cg-badge secondary">+95%</div>
    </div>

    <h2 class="title mt-3 mb-2">Large Size Badges</h2>
    <div class="d-flex justify-content-between mt-2">
        <div class="cg-badge lg success">Active</div>
        <div class="cg-badge lg warning">InActive</div>
        <div class="cg-badge lg danger">Negative Growth</div>
        <div class="cg-badge lg primary">Positive Growth</div>
        <div class="cg-badge lg secondary">+95%</div>
    </div>
</div>
