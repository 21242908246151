import { RouterModule } from '@angular/router'
import { NgModule } from '@angular/core'
import { DocumentationComponent } from './documentation.component'
import { AdminSharedModule } from 'src/app/admin-panel/admin-shared/admin-shared.module'
import { TablesComponent } from './tables/tables.component'
import { GeneralItemsComponent } from '../general-items/general-items.component'

@NgModule({
    imports: [
        AdminSharedModule,
        RouterModule.forChild([
            {
                path: '',
                component: DocumentationComponent,
                children: [
                    { path: 'general', component: GeneralItemsComponent },
                    { path: 'tables', component: TablesComponent }
                ]
            }
        ])
    ],
    declarations: [
        DocumentationComponent,
        TablesComponent,
        GeneralItemsComponent
    ]
})
export class DocumentationModule { }
