import { AfterViewChecked, Component, OnInit } from '@angular/core'
import { PrismService } from 'src/app/services/prism.service'

@Component({
    selector: 'app-tables',
    templateUrl: './tables.component.html',
    styleUrls: ['./tables.component.css']
})
export class TablesComponent implements OnInit, AfterViewChecked {

    highlighted: boolean = false

    constructor(private highlightService: PrismService) {
    }

    ngAfterViewChecked() {
        if (!this.highlighted) {
            this.highlightService.highlightAll()
            this.highlighted = true
        }
    }

    ngOnInit() {
    }
}
