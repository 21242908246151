<div class="box">
    <div class="doc-navbar">
        <a class="doc-link" routerLink="general">General Items</a>
        <div class="pipe">|</div>
        <a class="doc-link" routerLink="tables">Tables</a>
    </div>
</div>
<div class="box">
    <div class="buttons-container">
        <button class="cg primary sm" (click)="setTheme('default')">Light Theme</button>
        <button class="cg primary sm ms-2" (click)="setTheme('dark')">Dark Theme</button>
    </div>
</div>
<router-outlet></router-outlet>
